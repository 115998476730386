import React, { useContext, useMemo, useState } from 'react';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';
import HeroSmallComponent from '../../components/HeroImage/heroSmall.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchBarSimpleComponent from '../../components/SearchBar/searchBarSimple.component';
import AccordionComponent from '../../components/Accordion/accordion.component';
import LikeComponent from '../../components/Like/like.component';
import CentralContentComponent from '../../components/Content/centralContent.component';
import VideoPlayer from '../../components/VideoPlayer/videoPlayer.component';
import ArticleComponent from '../../components/Related/article.component';
import ArticleContentComponent from '../../components/Content/articleContent.component';
import { useParams } from 'react-router-dom';
import { ContentBlocksItem, Related, strapiDoc } from '../../@types/strapiDocs';
import useLoadData from '../../hooks/useLoadData';
import SkeletonBodyLoaderComponent from '../../components/Loader/Skeleton/skeletonBody.component';
import { UserContext } from '../../context/UserContext';
import Swal from 'sweetalert2';
import SearchBarComponent from '../../components/SearchBar/searchBar.component';

const ModuleDetailScreen: React.FC = () => {
  const [isReviewSubmitted, setIsReviewSubmitted] = useState<boolean>(false);
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }

  const { user } = userContext;

  console.log('USER', user);

  const { collection, module } = useParams();

  // Asegúrate de que la URL esté construida correctamente, sin duplicar "api"
  const endpoint = `/modules`;
  const options = useMemo(() => {
    const clientRolsIds: string[] = JSON.parse(
      localStorage.getItem('clientRolsIds') || '[]'
    );
    if (clientRolsIds.length > 0) {
      return { rols: { rolId: { $in: clientRolsIds } } };
    }
    return {};
  }, [collection]);

  const { data, loading, error } = useLoadData<strapiDoc[]>(
    endpoint || '', // Pasa la URL correctamente
    {
      populate: {
        Tag: true,
        VideoInner: true,
        Collection: {
          populate: {
            related: true,
            Download: true,
            Image: true, // Populate directo de 'Collection'
          },
        },
        ContentBlocks: {
          populate: {
            Image: true,
          },
        },
        Author: {
          populate: {
            ProfilePic: true,
          },
        },
      },
      filters: {
        Collection: { Slug: collection || '' },
        Slug: module,
        ...options,
      },
    }
  );

  // Cambiamos el endpoint a `/modules` para cargar solo los módulos
  const { data: modulesData } = useLoadData<strapiDoc[]>('/modules', {
    populate: '*',
    filters: { ...options },
  });

  console.log('collection', collection); // Verifica que sea "ventas"
  console.log('Fetching from:', endpoint);
  console.log('MODULE', data);
  console.log('ERROR', error);

  if (loading)
    return (
      <div className="container-fluid pt-3">
        <SkeletonBodyLoaderComponent />
      </div>
    );
  if (error)
    return <div className="badge bg-danger">Error loading data: {error}</div>;

  // Collection
  const collectionName =
    (data && data[0]?.attributes?.Collection?.data[0]?.attributes?.Title) || '';
  const collectionSlug =
    (data && data[0]?.attributes?.Collection?.data[0]?.attributes?.Slug) || '';
  const collectionDescription =
    (data &&
      data[0]?.attributes?.Collection?.data[0]?.attributes?.Description) ||
    '';
  const collectionImage =
    (data &&
      data[0]?.attributes?.Collection?.data[0]?.attributes?.Image?.data
        ?.attributes?.url) ||
    '';
  const related =
    (data &&
      data[0]?.attributes?.Collection?.data?.flatMap((collectionItem) => {
        const relatedItems = collectionItem.attributes?.related?.data;

        if (Array.isArray(relatedItems) && relatedItems.length > 0) {
          console.log('Related items found:', relatedItems);
          return relatedItems.map((item: Related) => ({
            slug: item.attributes?.Slug || '',
            title: item.attributes?.SuperTitle || '',
          }));
        } else {
          console.log('No related items or not an array');
        }

        return [];
      })) ||
    [];

  console.log('Related links:', related);

  const filteredRelated = related.filter((item) => item.slug !== module);

  // Transformar `related` en la estructura que espera `ArticleComponent`
  const relatedLinks = filteredRelated.map((item) => ({
    href: `/${collectionSlug}/${item.slug}`, // Construye la URL usando el slug
    text: item.title, // Usa el título para el texto del link
  }));

  // Author
  const authorName =
    (data && data[0]?.attributes?.Author?.data?.attributes?.Name) || '';
  const authorPic =
    (data &&
      data[0]?.attributes?.Author?.data?.attributes?.ProfilePic?.data
        ?.attributes?.url) ||
    '';

  // Content
  const title = (data && data[0]?.attributes?.SuperTitle) || '';
  const video = (data && data[0]?.attributes?.VideoInner?.[0]?.Url) || '';
  const tags =
    (data &&
      data[0]?.attributes?.Tag?.data
        .map((tagItem) => tagItem.attributes?.Title)
        .filter(Boolean)) ||
    [];
  const tagsString = tags.join(', ');
  const tagsArray = tagsString.split(',').map((tag) => tag.trim());
  const date = (data && data[0]?.attributes?.updatedAt) || '';
  // Función para formatear la fecha
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0-11
    const year = String(date.getFullYear()).slice(-2); // Últimos 2 dígitos del año
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const formattedDate = formatDate(date);

  // Blocks
  const block =
    (data &&
      data[0]?.attributes?.ContentBlocks?.map((item: ContentBlocksItem) => {
        const imageUrl =
          item.Image?.data &&
          Array.isArray(item.Image.data) &&
          item.Image.data.length > 0
            ? item.Image.data[0]?.attributes?.url || ''
            : '';

        return {
          title: item.Title || '',
          image: imageUrl,
          description: item.Description || '',
          slug: item.Slug || '',
        };
      })) ||
    [];

  const handleReviewSubmit = async (review: string) => {
    const data = {
      Collection: collection,
      Module: module,
      User: user.name,
      Review: review,
    };

    try {
      const response = await fetch('https://cms.siticsoftware.com/api/reviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
        },
        body: JSON.stringify({ data }), // Envolviendo `data` dentro de `{ data }` como Strapi espera
      });

      if (!response.ok) {
        const errorResponse = await response.json(); // Obtener detalles del error
        throw new Error(`Error al enviar la reseña: ${errorResponse.message}`);
      }

      console.log('Reseña enviada exitosamente:', data);
      setIsReviewSubmitted(true); // Deshabilitar los botones después de enviar la reseña
      // Mostrar alerta de éxito
      Swal.fire({
        icon: 'success',
        title: '¡Reseña enviada!',
        text: 'Tu reseña de contenido ha sido enviada exitosamente.',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const domain = 'localhost:3000';
  const url = domain + '/' + collection + '/' + module;

  const searchOptions =
    modulesData?.map((module) => ({
      value:
        module.attributes?.Slug ||
        module.attributes?.Title?.toLowerCase() ||
        'untitled',
      label: module.attributes?.SuperTitle || 'Untitled',
    })) || [];

  return (
    <>
      <HeaderComponent theme="light" />
      <HeroSmallComponent
        title={collectionName}
        theme="color"
        description={collectionDescription}
        imageUrl={collectionImage}
        url="/"
      />
      <section className="module">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 col-12 mb-4">
              <div className="aside-stick">
                <SearchBarComponent
                  options={searchOptions}
                  itemsModules={searchOptions} // Pasa todos los módulos para seleccionar
                  moduleSlug={collectionSlug} // Parámetro `module` con el slug actual
                  showChecker={false} // Desactiva el checker si es necesario
                />
                <AccordionComponent
                  name={
                    (collection ?? '').charAt(0).toUpperCase() +
                    (collection ?? '').slice(1).toLowerCase()
                  }
                  collection={collection}
                />
              </div>
            </aside>
            <section className="col-lg-9 col-12">
              <div className="content-main">
                <section className="content-block">
                  <CentralContentComponent
                    title={title}
                    authorName={authorName}
                    date={formattedDate}
                    time="20"
                    tags={tagsArray}
                    authorUrl={authorPic}
                    username={user.name}
                    module={collection}
                    url={url}
                  />
                  {video && <VideoPlayer videoUrl={video} tag={true} />}
                  {relatedLinks.length > 0 && (
                    <div className="related-content">
                      <div className="row">
                        <div className="col-12">
                          <ArticleComponent
                            title="Temas más consultados"
                            links={relatedLinks}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </section>
                {block.map((item, index) => (
                  <section className="content-block" key={index}>
                    <ArticleContentComponent
                      id={item.slug}
                      title={item.title}
                      description={item.description}
                      image={item.image}
                    />
                  </section>
                ))}
              </div>
            </section>
          </div>
        </div>
      </section>
      <LikeComponent
        collection={collection}
        module={module}
        username={user.name}
        onReviewSubmit={handleReviewSubmit}
        isReviewSubmitted={isReviewSubmitted}
      />
      <FooterComponent />
    </>
  );
};

export default ModuleDetailScreen;
